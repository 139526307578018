import axios from "axios"

export const addEvent = (authtoken, event) => {
    if (event) {
        axios.post('https://data.mongodb-api.com/app/gymly-qrtsy/endpoint/addEvent', { body: event })
            .then(res => {
                // console.log(res);
            });
    }
}

export const getEvent = (eventId) => {
    return axios.get(`https://data.mongodb-api.com/app/gymly-qrtsy/endpoint/getEvent`)
        .then(res => {
            return res.data;
        });
}

export const editEvent = (authtoken, event) => {
    if (event) {
        axios.put('https://data.mongodb-api.com/app/gymly-qrtsy/endpoint/editEvent', { body: event })
            .then(res => {
                // console.log(res);
            });
    }
}

export const deleteEvent = (authtoken, eventId) => {
    if (eventId) {
        axios.delete('https://data.mongodb-api.com/app/gymly-qrtsy/endpoint/deleteEvent?_id=' + eventId)
            .then(res => {
                // console.log(res);
            });
    }
}