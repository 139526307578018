import React, { useState } from 'react'
import ObjectID from 'bson-objectid';
import { dateTimeString, minutesSince } from '../components/Functions'
import { addLog, getLogsForDate } from '../api/log'
import { editMember } from '../api/member'
import { BsArrowRepeat } from 'react-icons/bs';


function Attendance({ members, setMembers, logs, setLogs }) {
  const [logSearch, setLogSearch] = useState('');
  const [memberSearch, setMemberSearch] = useState('');
  const [refreshTime, setRefreshTime] = useState();

  const filteredLogs = logs.filter(log => (log.memberName).toLowerCase().includes(logSearch.toLowerCase()))
  const filteredMembers = members.filter(member => (member.firstName + member.lastName).toLowerCase().includes(memberSearch.toLowerCase()))




  const handleCheckIn = (event, member) => {
    event.preventDefault();
    const newLogs = [...logs];
    const newLog = {
      _id: ObjectID().toString(),
      memberName: member.firstName + " " + member.lastName,
      memberId: member._id,
      time: new Date()
    }
    newLogs.push(newLog);
    setLogs(newLogs);

    const newMembers = [...members];
    newMembers[members.indexOf(member)] = {
      ...member,
      lastActive: new Date()
    };
    setMembers(newMembers);
    addLog("authtoken", newLog);
    editMember("authtoken", newMembers[members.indexOf(member)]);
  }

  const handleRefresh = () => {
    setRefreshTime(new Date());

    getLogsForDate().then(function (result) {
      setLogs(result);
    });
  }

  const handleDateChange = (event) => {
    getLogsForDate(event.target.value).then(function (result) {
      setLogs(result);
    })
  }

  // let timer;
  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   timer = window.setInterval(() => {
  //     handleRefresh();
  //   }, 600000);
  //   return () => {
  //     window.clearInterval(timer);
  //   };
  // }, []);

  return (
    <>
      <section className='attendance'>
        <div className="attendance-allmembers">
          <div className='attendance-allmembers-heading'>
            <h3>All Members</h3>
            <input type="text" id="search" onChange={(e) => setMemberSearch(e.target.value)} placeholder="Search" />
          </div>
          <table>
            <tbody>
              {filteredMembers.map(member => <tr key={member._id}><td>{(member.firstName + " " + member.lastName)}</td><td> <button onClick={(event) => handleCheckIn(event, member)} disabled={minutesSince(member.lastActive) < 30}>Check In</button> </td></tr>)}
            </tbody>
          </table>
        </div>
        <div className='attendance-member-container'>
          <div className='attendance-member-name'>
            <table cellSpacing={0}>
              <thead>
                <tr className='member-name-h'>
                  <th className='member-name-h1'>Member Name <input type="text" id="search" onChange={(e) => setLogSearch(e.target.value)} placeholder='Search' /></th>
                  <th className='member-name-h2'>
                    <p style={{ fontWeight: 400, fontSize: "1rem", display: "inline" }}>Showing logs for: </p>
                    <input type="date" id="logDate" onChange={handleDateChange} />
                    <br />
                    Check-In Time
                    {refreshTime - new Date() < 600000 ?
                      <BsArrowRepeat className="refreshLogsButtonDisabled" />
                      : <BsArrowRepeat className="refreshLogsButton" onClick={() => handleRefresh()} />
                    }

                  </th>
                </tr>
              </thead>
              <tbody className='attendance-member-name-body'>
                {filteredLogs.map(log => <tr key={log._id}><td className='member-name'>{log.memberName}</td><td className='member-check-time'>{dateTimeString(log.time)}</td></tr>)}
              </tbody>
            </table>
          </div>
          {/* <form>Export logs from <input type="date" /> To <input type="date" /><button type="submit">Export</button></form> */}
        </div>
      </section>
    </>
  )
}

export default Attendance