import React, { useRef, useCallback, useState } from 'react'
import { dateToString } from '../Functions'
import html2canvas from 'html2canvas';
import Webcam from 'react-webcam';
import QRCode from 'react-qr-code'

function TakePhoto({ imageSrc, setImageSrc, setScreen }) {
    const webcamRef = useRef(null);


    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setImageSrc(imageSrc)
        },
        [webcamRef, setImageSrc]
    );

    return (
        <>
            {imageSrc ?
                <div className='member-id-img'>
                    <img src={imageSrc} alt={"User Profile"} style={{ maxHeight: '360px' }} />
                    <button onClick={(e) => { e.preventDefault(); setImageSrc('') }}> Retake Photo </button>
                    <button onClick={() => setScreen(false)}> Next </button>
                </div> :
                <div className='member-camera'>
                    <Webcam
                        ref={webcamRef}
                        forceScreenshotSourceSize
                        videoConstraints={{
                            height: 720,
                            width: 1280
                        }}
                        // height="360"
                        // width="640"
                        height="100%"
                        width="100%"
                    />
                    <button onClick={capture}>Capture Photo</button>
                </div>
            }

        </>
    );
};

function IDConfirmation({ member, setScreen, imageSrc, setTrigger }) {
    const printRef = useRef(null);

    const handleDownloadImage = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);

        const data = canvas.toDataURL('image/png');
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
            link.href = data;
            link.download = 'image.png';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
    };

    return (
        <div className='member-id-qr-popup'>
            <div ref={printRef} id="printable" className='printable'>

                <div className='memberid-banner-container'>
                    <div className='memberid-banner' style={{ backgroundColor: '#0FC344' }} />
                    <div className='memberid-banner' style={{ backgroundColor: '#FDFE04' }} />
                    <div className='memberid-banner' style={{ backgroundColor: '#FC0517' }} />
                </div>
                <div className='memberid-profile-container'>
                    <div className='memberid-qr-profile'>
                        <img src={imageSrc} alt="Profile" style={{ height: '200px', width: '200px', objectFit: 'cover' }} />
                        <h1>{member.firstName} {member.lastName}</h1>
                    </div>
                </div>
                <div className='memberid-qr-cover'>
                    <img src="logo.png" alt="Gym Logo" style={{ height: '150px' }} />
                    <div className='memberid-qr-container'>
                        <h3 style={{color: 'white', paddingBottom: '5px'}}> Expires: {dateToString(member.expiration)} </h3>
                        <QRCode value={member._id} size={120}/>                        
                    </div>
                </div>
                
            </div>
            <div className='memberid-qr-btns'>
                <button onClick={() => setScreen(true)}> Previous </button>
                <button onClick={() => { handleDownloadImage(); setTrigger(false) }}> Download ID </button>
            </div>
        </div>
    )
};

function MemberID({ member, setTrigger }) {
    const [screen, setScreen] = useState(true);
    const [imageSrc, setImageSrc] = useState('');

    return (
        <>
            {screen ?
                <TakePhoto imageSrc={imageSrc} setImageSrc={setImageSrc} setScreen={setScreen} />
                :
                <IDConfirmation member={member} setScreen={setScreen} imageSrc={imageSrc} setTrigger={setTrigger} />
            }
        </>
    )
}

export default MemberID