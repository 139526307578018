import React from 'react';
import { dateToString, timeConversion } from '../Functions';
import { BsPencilSquare, BsTrash } from 'react-icons/bs';
import { deleteEvent } from '../../api/event';




function EventDetails({ event, eventList, setEventList, setTrigger, setEditPopup }) {

    const handleDeleteEvent = (_id) => {
        if (window.confirm(`Are you sure you want to delete ${event.eventName}?`)) {
            const newEvents = eventList.filter(ev => ev !== event);
            deleteEvent("auth token", _id);
            setEventList(newEvents);
            setTrigger(false);
        }
    }
    return (
        <section>
            <h1> Event Details</h1>
            <h2>Event Information</h2>
            <hr />

            <table>
                <tbody>
                    <tr>
                        <td className='table-tag'>Event Name</td>
                        <td>{event.eventName}</td>
                    </tr>
                    <tr>
                        <td className='table-tag'>Event Date</td>
                        <td>{dateToString(event.date)}</td>
                    </tr>
                    <tr>
                        <td className='table-tag'>Time </td>
                        <td>{timeConversion(event.startTime) + " - " + timeConversion(event.endTime)}</td>
                    </tr>
                </tbody>
            </table>

            <h2> Target Members </h2>
            <hr />

            <table>
                <tbody>
                    <tr>
                        <td className='table-tag'>Gender</td>
                        <td>{event.gender.join(', ')}</td>
                    </tr>
                    <tr>
                        <td className='table-tag'>Age Range</td>
                        <td>{event.ageMin + " - " + event.ageMax}</td>
                    </tr>
                </tbody>
            </table>

            <div className='event-details-edit-btns'>
                <div className='event-edit-btn-icon'><BsTrash onClick={() => handleDeleteEvent(event._id)} /></div>
                <div className='event-edit-btn-icon'><BsPencilSquare onClick={() => setEditPopup(true)} /></div>
            </div>
        </section>
    )
}

export default EventDetails