import React, { useState } from 'react'
import { dateTimeString } from '../components/Functions'
import ObjectID from 'bson-objectid';
import { addLog } from '../api/log'


import '../App.css';

function Scanner({ members, setMembers, logs, setLogs }) {
  const [localLogs, setLocalLogs] = useState([]);
  const [scanner, setScanner] = useState("");

  const formSubmit = (event) => {
    event.preventDefault();
    setLocalLogs([[scanner, new Date()], ...localLogs]);

    const member = members.find(member => member._id === scanner);

    if (member) {
    const newLogs = [...logs];
    const newLog = {
      _id: ObjectID().toString(),
      memberName: member.firstName + " " + member.lastName,
      memberId: member._id,
      time: new Date()
    }
    newLogs.push(newLog);
    setLogs(newLogs);

      const newMembers = [...members];
      newMembers[members.indexOf(member)] = {
        ...member,
        lastActive: new Date()
      };
      setMembers(newMembers);
      addLog("authtoken", newLog);

    }
    setScanner("");    
  }

  return (
    <section className='scanner-section'>
      <form onSubmit={formSubmit}>
        <input type="text" value={scanner} onChange={(e) => setScanner(e.target.value)} autoFocus />
        <button>Scan</button>
      </form>
      <table cellSpacing={0}>
        <tbody>
          {localLogs.map((log, index) => <tr key={index}><td>{log[0]}</td><td>{dateTimeString(log[1])}</td></tr>)}
        </tbody>
      </table>
    </section>
  )
}

export default Scanner