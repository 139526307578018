import React from 'react'

function MessageForm({ selectedMembers }) {
    return (
        <section className='member-draft-message'>
            <h1>Draft Message</h1>
            <h2>Recipients</h2>
            <hr />
            <table cellSpacing={0}>
                <tbody>
                    {selectedMembers.map(member =>
                        <tr key={member._id}>
                            <td className='draft-name'>{member.firstName} {member.lastName}</td>
                            <td>{member.email}</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <h2>Subject</h2>
            <hr />
            <input placeholder='Subject' />

            <h2>Message</h2>
            <hr />
            <textarea placeholder='Message' />
            <br/>
            <button>Send Message</button>
        </section>
    )
}

export default MessageForm