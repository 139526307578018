import axios from "axios"

export const addLog = (authtoken, log) => {
    if (log) {
        axios.post('https://data.mongodb-api.com/app/gymly-qrtsy/endpoint/addLog', { body: log })
            .then(res => {
                // console.log(res);
            });
    }
}

export const getTableData = () => {
    return axios.get(`https://data.mongodb-api.com/app/gymly-qrtsy/endpoint/getTableData`)
        .then(res => {
            return res.data;
        });
}

export const getLogsForDate = (date) => {
    if (date) {
        return axios.get(`https://data.mongodb-api.com/app/gymly-qrtsy/endpoint/getLogsForDate?date=${date}`)
            .then(res => {
                return res.data;
            });
    } else {
        return axios.get(`https://data.mongodb-api.com/app/gymly-qrtsy/endpoint/getLogsForDate`)
            .then(res => {
                return res.data;
            });
    }
}