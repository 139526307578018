import ObjectID from 'bson-objectid';
import React from 'react';
import { useState } from 'react';
import { addMember, editMember } from '../../api/member';

function MemberForm({ members, member, setMembers, setTrigger, memberDetailsTrigger }) {
  const [inputs, setInputs] = useState({
    _id: member?._id || ObjectID().toString(),
    firstName: member?.firstName || '',
    lastName: member?.lastName || '',
    DOB: member?.DOB || '',
    phone: member?.phone || '',
    email: member?.email || '',
    gender: member?.gender || '',
    race: member?.race || '',
    joined: member?.joined || new Date(),
    expiration: member?.expiration || new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    lastActive: member?.lastActive || "New Member",
    houseId: member?.houseId || ObjectID().toString(),
    address: member?.address || {
      street: member?.address.street || '',
      city: member?.address.city || '',
      state: member?.address.state || '',
      zipcode: member?.address.zipcode || '',
    }
    
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value.toUpperCase();

    if (["street", "city", "state", "zipcode"].includes(event.target.name)) {
      setInputs({
        ...inputs,
        address: {
          ...inputs.address,
          [name]: value
        }
      });
    } else {
      setInputs(values => ({ ...values, [name]: value }))
    }
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (member) {
      const newMembers = [...members];
      newMembers[members.indexOf(member)] = inputs;
      setMembers(newMembers);
      editMember("hello", inputs);
    } else {
      setMembers([...members, inputs]);
      addMember("hello", inputs);
    }
    setTrigger(false);
    memberDetailsTrigger(inputs);
  }

  return <div className='add-member-form'>
    <h1>Member Form</h1>
    <form onSubmit={handleFormSubmit}>
      <div className='member-presonal-info'>
        <h2>Personal Information</h2>
        <hr />
        <input type="text" name="firstName" placeholder='First Name' value={inputs.firstName} onChange={handleChange} required></input>
        <input type="text" name="lastName" placeholder='Last Name' value={inputs.lastName} onChange={handleChange} required></input>
        <br />
        <input type="date" name="DOB" value={inputs.DOB} onChange={handleChange} required></input>
        <br />
        <div className='member-gender-form'>
          Gender:&nbsp;
          <label><input type="radio" value="MALE" name="gender" checked={inputs.gender === "MALE"} onChange={handleChange} required /> &nbsp;Male </label>&nbsp;&nbsp;
          <label><input type="radio" value="FEMALE" name="gender" checked={inputs.gender === "FEMALE"} onChange={handleChange} required /> &nbsp;Female </label>
        </div>
        <br />
        <label>
          Race:&nbsp;
          <select value={inputs.race} onChange={handleChange} name="race">
            <option value="">-- Select -- </option>
            <option value="WHITE">White</option>
            <option value="BLACK" selected>Black</option>
            <option value="NATIVE AMERICAN">Native American</option>
            <option value="ASIAN">Asian</option>
            <option value="PACIFIC ISLANDER">Pacific Islander</option>
            <option value="HISPANIC">Hispanic</option>
          </select>
        </label>
      </div>

      <h2>Contact Information</h2>
      <hr />
      <input type="text" name="phone" placeholder='Phone Number (Only Digits)' value={inputs.phone} onChange={handleChange} required></input>
      <input type="email" name="email" placeholder='Email Address' value={inputs.email} onChange={handleChange} required></input>
      <br />

      <h2>Address</h2>
      <hr />
      <input type="text" name="street" placeholder='Street Address' value={inputs.address.street} onChange={handleChange} required></input>
      <input type="text" name="city" placeholder='City' value={inputs.address.city} onChange={handleChange} required></input>
      <br />
      <input type="text" name="state" placeholder='State' value={inputs.address.state} onChange={handleChange} maxLength={2} required></input>
      <input type="text" name="zipcode" placeholder='Zip Code' value={inputs.address.zipcode} onChange={handleChange} maxLength={5} required></input>
      <br />
      <button type='submit'>{member ? "Edit Member" : "Add Member"}</button>
    </form>

  </div>
    ;
}

export default MemberForm;
