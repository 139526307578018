//Member Details Page
export function inCounty(zipcode) {
    const talbotZips = ["21601", "21663", "21673", "21625", "21654", "21671", "21657", "21662", "21612", "21679", "21676", "21647", "21665", "21652", "21653", "21624", "21606"];
    if (talbotZips.includes(zipcode)) {
        return "In-County";
    } else {
        return "Out of County";
    }
};

export function expiringSoon(expiration) {
    let today = new Date();
    today.setDate(today.getDate() + 14);
    return today >= new Date(expiration);
};

export function getAge(dateString) {
    var ageInMilliseconds = new Date() - new Date(dateString);
    return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365); // convert to years
}

// Attendance Page
export function minutesSince(dateString) {
    var ageInMilliseconds = new Date() - new Date(dateString);
    return Math.floor(ageInMilliseconds / 1000); // convert to minutes
}

// Events Page
export function timeConversion(time) {
    var date = new Date("1000-10-10T" + time);
    return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
}

//Used Multiple Places
export function dateToString(date) {
    return new Date(date).toLocaleDateString('en-US');
    // return new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' })
};

export function dateTimeString(date) {
    return new Date(date).toLocaleString('en-US');
}

export function makeCSV(content) {
    let csv = '';
    content.forEach(value => {
        value.forEach((item, i) => {
            let innerValue = item === null ? '' : item.toString();
            let result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0) {
                result = '"' + result + '"'
            }
            if (i > 0) { csv += ',' }
            csv += result;
        })
        csv += '\n';
    })
    return csv
}