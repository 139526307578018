import React from 'react'
import ReactApexChart from 'react-apexcharts'

function Chart({tableData }) {
    const options = {
        chart: {
            type: "area",
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
        },
        xaxis: {
            categories: tableData.categories,
            // max:20,
        },
        legend: {
            show: false,
        },
    };

    return (
        <div id="chart" className='dash-chart-g' >
            <ReactApexChart options={options} series={tableData.series} type="area" height="100%" />
        </div>
    )
}

export default Chart