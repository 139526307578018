import React from 'react';
import './Popup.css';
import { BsXLg } from "react-icons/bs";

function Popup(props) {
  return (props.trigger) && (
    <div className='popup' onClick={() => props.setTrigger(false)}>
      <div className='popup-inner' onClick={(e) => e.stopPropagation()}>
        <BsXLg className='close-btn' onClick={() => props.setTrigger(false)}/>
        {props.children}
      </div>
    </div>
  )
}

export default Popup;
