import ObjectID from 'bson-objectid';
import React from 'react';
import { useState } from 'react';
import { addEvent, editEvent } from '../../api/event';

function EventForm({ event, eventList, setEventList, setTrigger, setEventDetails }) {
  const [inputs, setInputs] = useState({
    _id: event?._id || ObjectID().toString(),
    eventName: event?.eventName || '',
    date: event?.date.substring(0,10) || '',
    startTime: event?.startTime || '',
    endTime: event?.endTime || '',
    ageMin: event?.ageMin || '',
    ageMax: event?.ageMax || '',
    gender: event?.gender || '',
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const handleCheckbox = (e) => {
    if (e.target.checked) {
      setInputs(values => ({ ...values, [e.target.name]: [...inputs.gender, e.target.value] }))
    } else {
      setInputs(values => ({ ...values, [e.target.name]: inputs.gender.filter(gender => gender !== e.target.value) }))
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const finalEvent = { ...inputs, date: inputs.date + "T04:00:00.000Z" }

    if (event) {
      const newEventList = [...eventList];
      newEventList[eventList.indexOf(event)] = finalEvent;
      setEventList(newEventList);
      setEventDetails(finalEvent);
      editEvent("hello", finalEvent);
    } else {
      const newEventList = [...eventList, finalEvent];
      setEventList(newEventList);
      addEvent("hello", finalEvent);
    }
    setTrigger(false);
  }

  return <div className='event-form'>
    <h1>Event Form</h1>
    <form onSubmit={handleFormSubmit}>
      <h2>Event Information</h2>
      <hr />
      <input type="text" name="eventName" placeholder='Event Title' value={inputs.eventName} onChange={handleChange}></input>
      <br />
      <input type="date" name="date" value={inputs.date} onChange={handleChange}></input>
      <input type="time" name="startTime" value={inputs.startTime} onChange={handleChange}></input>
      <span> To </span>
      <input type="time" name="endTime" value={inputs.endTime} onChange={handleChange}></input>
      <br />
      <h2>Event Targets</h2>
      <hr />
      <input type="text" name="ageMin" placeholder='Youngest Age' value={inputs.ageMin} onChange={handleChange}></input>
      <input type="text" name="ageMax" placeholder='Oldest Age' value={inputs.ageMax} onChange={handleChange}></input>
      <br />
      <div className='event-form-gender'>
        <label><input type="checkbox" value="Male" name="gender" checked={inputs.gender.includes("Male")} onChange={handleCheckbox} /> Male </label>
        <label><input type="checkbox" value="Female" name="gender" checked={inputs.gender.includes("Female")} onChange={handleCheckbox} /> Female </label>
      </div>
      <br />
      <h2>Message Details</h2>
      <hr />
      <textarea name="messageDetails" placeholder='Message..'></textarea>
      <br />
      <button type='submit'>{event ? "Edit event" : "Add event"}</button>
    </form>

  </div>
    ;
}

export default EventForm;
