import axios from "axios"

export const addMember = (authtoken, member) => {
    if (member) {
        axios.post('https://data.mongodb-api.com/app/gymly-qrtsy/endpoint/addMember', { body: member })
            .then(res => {
                // console.log(res);
            });
    }
}

export const getMember = () => {
    return axios.get(`https://data.mongodb-api.com/app/gymly-qrtsy/endpoint/getMember`)
        .then(res => {
            return res.data;
        });
}

export const editMember = (authtoken, member) => {
    if (member) {
        axios.put('https://data.mongodb-api.com/app/gymly-qrtsy/endpoint/editMember', { body: member })
            .then(res => {
                // console.log(res);
            });
    }
}

export const deleteMember = (authtoken, memberId) => {
    if (memberId) {
        axios.delete('https://data.mongodb-api.com/app/gymly-qrtsy/endpoint/deleteMember?_id=' + memberId)
            .then(res => {
                // console.log(res);
            });
    }
}