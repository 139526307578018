import React from 'react'
import { useState } from 'react';
import { signIn } from '../api/auth';

function Login({setUser, user}) {
  const [inputs, setInputs] = useState({
    username: '',
    password: ''
  });

  const handleChange = (e) => {
    setInputs(values => ({ ...values, [e.target.name]: e.target.value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn(inputs, setUser);
  };


  

  return (
    <section className='login-section'>
      <div className="login-container">
        <form onSubmit={handleSubmit}>
          <h1> BAAM Athletic Center</h1>
          <input autoFocus type="text" name='username' value={inputs.username} onChange={handleChange} placeholder='Username' />
          <br />
          <input type="password" name='password' value={inputs.password} onChange={handleChange} placeholder='Password' />
          <br />
          {user === "Incorrect Credentials" ? <p id='login-failure'>Incorrect Credentials</p> : null}
          <button>Login</button>
        </form>
      </div>

      <div className='login-bg'>
        <img src="logo.png" alt='Login background' />
      </div>
    </section>
  )
}

export default Login