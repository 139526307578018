import React, { useState } from 'react';
import { inCounty, dateToString, expiringSoon, getAge } from '../Functions';
import Popup from '../Popup/Popup';
import MemberID from '../MemberID/MemberID';
import { BsArrowClockwise, BsPencilSquare, BsTrash } from 'react-icons/bs';
import { deleteMember } from '../../api/member';

function MemberDetails({ members, member, setEditPopup, setMembers, setTrigger }) {
    const [memberIDPopup, setMemberIDPopup] = useState(false);

    const handleDeleteMember = (_id) => {
        if (window.confirm(`Are you sure you want to delete ${member.firstName} ${member.lastName}?`)) {
            const newMembers = members.filter(mem => mem !== member);
            deleteMember("auth token", _id);
            setMembers(newMembers);
            setTrigger(false);
        }
    }

    const renewMember = () => {
        if (window.confirm("Please confirm with the member that their information is up to date. Edit their profile as needed.")) {
            const newMembers = [...members];
            newMembers[members.indexOf(member)].expiration = new Date().setFullYear(new Date().getFullYear() + 1);
            setMembers(newMembers);
        }
    }

    return (
        <>
            <section className="member-details-popup">
                <h1>Member Details</h1>

                <h2> Personal Information</h2>
                <hr />
                <table>
                    <tbody>
                        <tr>
                            <td className='table-tag'>Member Name</td>
                            <td>{member.firstName + " " + member.lastName}</td>
                        </tr>
                        <tr>
                            <td className='table-tag'>Age</td>
                            <td>{getAge(member.DOB) + " years old"}</td>
                        </tr>
                        <tr>
                            <td className='table-tag'> Gender </td>
                            <td> {member.gender} </td>
                        </tr>
                        <tr>
                            <td className='table-tag'> Race </td>
                            <td> {member.race} </td>
                        </tr>
                    </tbody>
                </table>

                <h2> Membership Details </h2>
                <hr />
                <table>
                    <tbody>
                        <tr>
                            <td className='table-tag'>Member ID</td>
                            <td>{member._id} </td>
                        </tr>
                        {/* <tr>
                            <td className='table-tag'>House ID</td>
                            <td>{member.houseId}</td>
                        </tr> */}
                        <tr>
                            <td className='table-tag'>Member Since</td>
                            <td> {dateToString(member.joined)} </td>
                        </tr>
                        <tr>
                            <td className='table-tag'>Valid Till</td>
                            <td> {dateToString(member.expiration)}
                                {expiringSoon(member.expiration)
                                    &&
                                    <BsArrowClockwise onClick={() => { renewMember() }} style={{ color: 'blue', fontSize: '1.2rem', verticalAlign: 'middle' }} />
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>


                <h2>Contact Details </h2>
                <hr />

                <table>
                    <tbody>
                        <tr>
                            <td className='table-tag'>Phone No.</td>
                            <td>{member.phone}</td>
                        </tr>
                        <tr>
                            <td className='table-tag'>Email</td>
                            <td>{member.email}</td>
                        </tr>
                    </tbody>
                </table>

                <h2> Address </h2>
                <hr />

                <table>
                    <tbody>
                        <tr>
                            <td className='table-tag'>Street</td>
                            <td>{member.address.street}</td>
                        </tr>
                        <tr>
                            <td className='table-tag'>City, State </td>
                            <td>{member.address.city + ", " + member.address.state.toUpperCase()}</td>
                        </tr>
                        <tr>
                            <td className='table-tag'>Zipcode </td>
                            <td>{member.address.zipcode}</td>
                        </tr>
                        <tr>
                            <td className='table-tag'>County Status </td>
                            <td>{inCounty(member.address.zipcode)}</td>
                        </tr>
                    </tbody>
                </table>
                <div className='member-details-buttons'>
                    <button onClick={() => setMemberIDPopup(true)}> Member ID </button>
                    <div className='member-details-edit-btns'>
                        <div className='trash-icon member-edit-icon'>
                            <BsTrash onClick={() => handleDeleteMember(member._id)} />
                        </div>
                        <div className='pencil-icon member-edit-icon'>
                            <BsPencilSquare onClick={() => setEditPopup(true)} />
                        </div>
                    </div>
                </div>
            </section>
            <Popup trigger={memberIDPopup} setTrigger={setMemberIDPopup}>
                <MemberID member={member} setTrigger={setMemberIDPopup} />
            </Popup>
        </>
    )
}

export default MemberDetails