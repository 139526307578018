import React from 'react'
import { Link } from 'react-router-dom'



function Navbar() {
  return (
  <nav className='navigation'>
    
    <div className='logo'>  
        <Link to="/"><img src="logo.png" alt="Logo" style={{ maxHeight: '60px' }} /></Link>
    </div>

    <input type="checkbox" className="menu-btn" id="menu-btn"/>
    <label htmlFor="menu-btn" className="menu-icon">
        <span className="nav-icon"></span>
    </label>

    <ul className='menu'>
      <li><Link to="/">Dashboard</Link></li>
      <li><Link to="/attendance">Attendance</Link></li>
      <li><Link to="/members">Members</Link></li>
      <li><Link to="/events">Events</Link></li>
      <li><Link to="/scanner">Scanner</Link></li>
    </ul>

    {/* <div className='nav-profile'>
      <img src='user_black.png'/>
    </div> */}

  </nav>)
}

export default Navbar