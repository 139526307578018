import React from 'react'
import Chart from '../components/Chart/Chart'
import { BsPeople, BsReceiptCutoff, BsCalendar3, BsPersonPlus, BsHouseDoor } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { dateToString } from '../components/Functions.js';

function Dashboard({ members, tableData, eventList, logs }) {
  
  const createEventSummary = () => {
    const previousSunday = new Date();
    previousSunday.setDate(previousSunday.getDate() - previousSunday.getDay());
    previousSunday.setHours(0, 0, 0, 0);
    const weeksInMS = 7 * 24 * 60 * 60 * 1000;

    const eventSummary = eventList.filter(event => new Date(event.date) - previousSunday >= 0 && new Date(event.date) - previousSunday <= weeksInMS);

    return eventSummary;
  }

  const getAttendanceToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const attendanceToday = logs.filter(attendance => new Date(attendance.time) - today > 0).length;
    return attendanceToday;
  }

  const loadTableData = (timeframe, tableData) => {
    let dailyTimeFrame = {};
    if (timeframe === 'daily') {
      tableData.forEach(item => {
        dailyTimeFrame[item._id.slice(0, 10)] ? dailyTimeFrame[item._id.slice(0, 10)] = dailyTimeFrame[item._id.slice(0, 10)] + item.count : dailyTimeFrame[item._id.slice(0, 10)] = item.count
      })
    }

    const series = (timeframe === 'hourly' ? [{
      name: 'Hourly Attendance',
      data: tableData.map(item => item.count)
    }] : [{
      name: 'Daily Attendance',
      data: Object.values(dailyTimeFrame)
    }])
    const categories = (timeframe === 'hourly' ? tableData.map(item => item._id + ":00") : Object.keys(dailyTimeFrame));
    return { series, categories };
  }

  const attendanceToday = getAttendanceToday();
  const eventSummary = createEventSummary();

  return (
    <>
      <section className='dashboard-section'>
        <div className="Dashboard-all-total">
          <div className='dash-total-member dash-top-boxes'><h2><BsPeople className="icon" /> Total Number of Members:</h2><p>{members.length}</p></div>
          <div className='dash-member-attendance dash-top-boxes'><h2><BsHouseDoor className="icon" /> Members Attended Today:</h2><p>{attendanceToday}</p></div>
          <div className='dash-week-event dash-top-boxes'><h2><BsCalendar3 className="icon" /> Events this Week:</h2><p>{eventSummary.length}</p></div>
        </div>
        <div className="Dashboard-Main">
          <Link to="/members"><div className="dash-add-member main-dash-box"><h2><BsPersonPlus className="icon" />&nbsp; View Members </h2></div></Link>
          <Link to="/attendance"><div className="dash-current-house main-dash-box"><h2><BsReceiptCutoff className="icon" />&nbsp;View Attendance Logs</h2></div></Link>
          <div className='dash-chart-1 dash-chart'><Chart tableData={loadTableData('hourly', tableData)} /></div>
          <div className='dash-chart-2 dash-chart'><Chart tableData={loadTableData('daily', tableData)} /></div>
          <div className='dash-events'>
            <h3>Upcoming Events</h3>
            {eventSummary.map(event => (
              <div key={event._id} className="dash-event-space"><h2>{event.eventName}</h2><p>{dateToString(event.date)} {event.startTime}</p></div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Dashboard