import axios from "axios";
// import jwt from "jsonwebtoken";

export const signIn = (credentials, setUser) => {
    if (credentials) {
        axios.post('https://data.mongodb-api.com/app/gymly-qrtsy/endpoint/signIn', { body: credentials })
            .then(res => {
                if (res.data !== "Incorrect Credentials") {
                    localStorage.setItem('token', res.data);
                    setUser(localStorage.getItem('token'));
                }else{
                    setUser("Incorrect Credentials");
                }
            })
            .catch(function (error) {
                console.log(error.toJSON());
                console.log(error.response);
            });
    }
};

// export const auth = async (req, res, next) => {
//     try {
//         const token = req.headers.authorization.split(" ")[1];

//         let decodedData = jwt.verify(token, "BAAMAthleticCenterSecret");
//         req._id = decodedData?._id;

//         next();
//     } catch (error) {
//         console.log(error);
//     }
// }