import React, { useEffect, useState } from 'react'
import Popup from '../components/Popup/Popup'
import EventDetails from '../components/EventDetails/EventDetails';
import EventForm from '../components/EventForm/EventForm';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../App.css';
import { dateToString, timeConversion } from '../components/Functions';

function Events({ eventList, members, setEventList }) {
  const [eventDetails, setEventDetails] = useState(null);
  const [newEventPopup, setNewEventPopup] = useState(false);
  const [calendarVal, setCalendarVal] = useState(new Date());
  const [dates, setDates] = useState([]);

  const printEvents = (date) => {
    let eventsToday = [];
    eventList.forEach(event => {
      if (dateToString(event.date) === dateToString(date)) {
        eventsToday.push(event);
      }
    });
    return (
      <div key={date}>
        <h3>{dateToString(date)}</h3>
        <hr />
        {eventsToday.map(event =>
          <div key={event._id} className="event-box">
            <h2>{event.eventName}</h2>
            <h4>{timeConversion(event.startTime) + " - " + timeConversion(event.endTime)}</h4>
            <button onClick={(e) => setEventDetails(event)}>Event Details</button>
          </div>
        )}
      </div>
    )
  }

  useEffect(() => {
    if (dateToString(calendarVal) === dateToString(new Date())) {
      const toSet = eventList.map(event => dateToString(event.date))
      setDates([...new Set(toSet)])
    } else {
      setDates([dateToString(calendarVal)])
    }
  }, [eventList, calendarVal])

  return (

    <section className='event-section'>
      <div className='event-container'>
        <div className='event-heading'>
          <h1>Upcoming Events</h1>
          <button onClick={(e) => { setNewEventPopup(true); setEventDetails(null) }}>Create New Event</button>
        </div>

        <div className="events-list-container">
          {dates.map((date) => printEvents(date))}
        </div>
      </div>

      <div className='event-calendar'>
        <Calendar className="event-cal" onChange={setCalendarVal} value={calendarVal} />
        <p>{calendarVal.toString()}</p>
      </div>

      <Popup trigger={eventDetails} setTrigger={setEventDetails}>
        <EventDetails event={eventDetails} setEditPopup={setNewEventPopup} eventList={eventList} setEventList={setEventList} setTrigger={setEventDetails} />
      </Popup>
      <Popup trigger={newEventPopup} setTrigger={setNewEventPopup}>
        <EventForm setTrigger={setNewEventPopup} members={members} eventList={eventList} setEventList={setEventList} event={eventDetails} setEventDetails={setEventDetails} />
      </Popup>
    </section>
  )
}

export default Events