import React, { useState } from "react";
import Popup from '../components/Popup/Popup';
import MemberForm from '../components/MemberForm/MemberForm';
import MessageForm from "../components/MessageForm/MessageForm";
import MemberDetails from "../components/MemberDetails/MemberDetails";
import { dateToString, dateTimeString, makeCSV } from '../components/Functions';


function Pagination({ membersPerPage, totalMembers, setCurrentPage, currentPage }) {
    const totalPages = Math.ceil(totalMembers / membersPerPage);
    let arr = [...Array(totalPages + 1).keys()].slice(1);
    let visible = [];

    if (arr.length > 5) {
        visible = [];

        if (currentPage < 5) {
            visible = arr.slice(1, 6);
        } else if (currentPage > totalPages - 3) {
            visible = arr.slice(totalPages - 6, totalPages - 1);
        } else {
            visible = arr.slice(currentPage - 3, currentPage + 2);
        }
        visible.push('...');
        visible.push(totalPages);
        visible.unshift('...');
        visible.unshift(1);
    } else {
        visible = arr;
    }

    return (
        <nav id="pagination">
            <div className="pagination">
                {visible.map((page, index) => (page === '...' ?
                    <span id="pagination-break" className="page-link" key={page + index}>{page}</span> :
                    <button className="page-link" key={page} onClick={() => { setCurrentPage(page); visible = []; }}>{page}</button>))}
            </div>
        </nav>
    );
}

function Members({ members, setMembers }) {
    const [newUserPopup, setNewUserPopup] = useState(false);
    const [memberDetailsPopup, setMemberDetailsPopup] = useState(null);
    const [messagePopup, setMessagePopup] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedMembers, setSelectedMembers] = useState([]);
    const filteredMembers = members.filter(member => (member.firstName + " " + member.lastName).toLowerCase().includes(search.toLowerCase()));
    const [currentPage, setCurrentPage] = useState(1);
    const membersPerPage = 25;

    //Pagination
    const indexOfLastPost = currentPage * membersPerPage;
    const indexOfFirstPost = indexOfLastPost - membersPerPage;
    const currentMembers = filteredMembers.slice(indexOfFirstPost, indexOfLastPost);

    // Download CSV of members
    const exportMembers = (members) => {
        let password = window.prompt("Please enter the administrator password");
        if (password !== "kdaly@baaminc.org") {
            alert("Incorrect password");
            return;
        }
        let contents = [];
        contents.push(["MemberID", "First Name", "Last Name", "DOB", "Phone", "Email", "Gender", "Race", "Joined", "Last Active", "Expiration", "Street", "City", "State", "Zipcode"]);
        members.forEach(row => {
            contents.push([row._id, row.firstName, row.lastName, row.DOB, row.phone, row.email, row.gender, row.race, row.joined, row.lastActive, row.expiration, row.address.street, row.address.city, row.address.state, row.address.zipcode])
        });
        let output = makeCSV(contents);

        var data = new Blob([output], { type: 'text/csv' });
        var csvURL = window.URL.createObjectURL(data);
        let tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'BAAMMembersExport.csv');
        tempLink.click();
        window.URL.revokeObjectURL(csvURL);
    }

    return (
        <>
            <main className='member-main'>
                <section className="members-section">

                    <div className="member-section-top">
                        <div className="member-buttons">
                            <button onClick={() => setMessagePopup(true)} disabled={selectedMembers.length === 0}> Draft Message </button>
                            <button onClick={() => { setNewUserPopup(true); setMemberDetailsPopup(null) }}> Add Member </button>
                        </div>
                        <input type="text" id="search" onChange={(e) => setSearch(e.target.value)} placeholder="Search" />
                    </div>

                    <table cellSpacing={0}>
                        <thead className="member-table-heading">
                            <tr>
                                <th><input type="checkbox" checked={selectedMembers.length === members.length} onChange={(e) => e.target.checked ? setSelectedMembers([...members]) : setSelectedMembers([])} /></th>
                                <th>Name</th>
                                <th>Member ID</th>
                                <th>Expires</th>
                                <th>Last Active</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentMembers.map(member => (
                                <tr key={member._id} className={selectedMembers.includes(member) ? "selected" : ""} >
                                    <td><input type="checkbox" checked={selectedMembers.includes(member)} onChange={(e) => e.target.checked ? setSelectedMembers([...selectedMembers, member]) : setSelectedMembers(selectedMembers.filter(mem => mem !== member))} /></td>
                                    <td onClick={() => setMemberDetailsPopup(member)}> {member.firstName + " " + member.lastName} </td>
                                    <td>{member._id}</td>
                                    <td>{dateToString(member.expiration)}</td>
                                    <td>{member.lastActive === 'New Member' ? 'New Member' : dateTimeString(member.lastActive)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
                <Pagination className="member-pagination" membersPerPage={membersPerPage} totalMembers={filteredMembers.length} setCurrentPage={setCurrentPage} currentPage={currentPage} />
                <button className="export-button" onClick={() => exportMembers(members)}>{selectedMembers.length === 0 ? "Export All Members" : "Export Selected Members"}</button>
            </main>

            <Popup trigger={memberDetailsPopup} setTrigger={setMemberDetailsPopup}>
                <MemberDetails members={members} member={memberDetailsPopup} setMembers={setMembers} setTrigger={setMemberDetailsPopup} setEditPopup={setNewUserPopup} />
            </Popup>

            <Popup trigger={newUserPopup} setTrigger={setNewUserPopup}>
                <MemberForm members={members} member={memberDetailsPopup} setMembers={setMembers} setTrigger={setNewUserPopup} memberDetailsTrigger={setMemberDetailsPopup} />
            </Popup>

            <Popup trigger={messagePopup} setTrigger={setMessagePopup}>
                <MessageForm selectedMembers={selectedMembers} />
            </Popup>
        </>
    )
}

export default Members