import React, { useEffect, useState } from 'react';
import Dashboard from './views/Dashboard';
import Attendance from "./views/Attendance";
import Events from "./views/Events";
import Members from "./views/Members";
import Scanner from "./views/Scanner";
import Login from './views/Login';
import Navbar from './components/Navbar/Navbar';
import { getMember } from './api/member';
import { getEvent } from './api/event';
import { getTableData, getLogsForDate } from './api/log';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import decode from 'jwt-decode';
import './App.css';

function App() {
  const [members, setMembers] = useState([]);
  const [logs, setLogs] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [user, setUser] = useState(localStorage.getItem('token'));
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getMember().then(function (result) {
      setMembers(result);
    });

    getEvent().then(function (result) {
      setEventList(result.sort((a, b) => a.date - b.date));
    });

    getLogsForDate().then(function (result) {
      setLogs(result);
    });

    getTableData().then(function (result) {
      setTableData(result.sort((a, b) => a._id > b._id ? 1 : -1));
    });

    const token = user;

    if (token && token !== "Incorrect Credentials") {
      const decodedData = decode(token);

      if (decodedData.username !== "BAAMAthleticCenter" || decodedData._id !== "62436e737f581a3e0825c131") {
        localStorage.removeItem('token');
        setUser(null);
      }

      if (decodedData.exp < (new Date().getTime() / 1000)) {
        localStorage.removeItem('token');
        setUser(null);
      }
    }
  }, [user]);

  return (
    <Router>
      <Navbar />
      <Routes>
        {(user && user !== "Incorrect Credentials") ?
          <>
            <Route path="/" element={<Dashboard members={members} eventList={eventList} logs={logs} tableData={tableData} />} />
            <Route path="/attendance" element={<Attendance members={members} setMembers={setMembers} logs={logs} setLogs={setLogs} />} />
            <Route path="/members" element={<Members members={members} setMembers={setMembers} />} />
            <Route path="/events" element={<Events eventList={eventList} setEventList={setEventList} members={members} />} />
            <Route path="/scanner" element={<Scanner members={members} setMembers={setMembers} logs={logs} setLogs={setLogs} />} />
          </>
          :
          <>
            <Route path="*" element={<Navigate replace to="/" />} />
            <Route path="/" element={<Login setUser={setUser} user={user} />} />
          </>

        }

      </Routes>
    </Router>
  );
}

export default App;